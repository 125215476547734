@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;500;700&display=swap');
/* font-family: 'Alegreya Sans SC', sans-serif; */

#root {
  min-height: inherit;
}

main {
  padding-bottom: 2rem;
  position: relative;
  min-height: calc(100% - 2rem);
}

h1 {
  background-color: hsl(0, 0%, 96%);
  font-family: 'Alegreya Sans SC', sans-serif;
  font-size: 3rem;
  text-align: center;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}

h1 > a, h3 > a {
  text-decoration: none;
}

a:visited {
  color: hsl(0, 0%, 20%);
}

a {
  color: hsl(0, 0%, 20%);
}

h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h3:nth-of-type(2) {
  margin-top: 1.5rem;
}

h4, h4 > button {
  font-size: 1.75rem;
  font-family: 'Alegreya Sans', sans-serif;
  margin-bottom: .5rem;
  border: none;
  background-color: hsla(0, 0%, 0%, 0);
}

h4 > button {
  border: none;
}

h5 {
  background-color: hsl(0, 0%, 96%);
  font-size: 1.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
}

h6 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: .5rem;
}

.primary-nav {
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: hsl(0, 0%, 96%);
  margin-bottom: 1.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  box-shadow: 0px 7px 10px -5px hsl(0, 0%, 20%);
  width: 100%;
}

.primary-nav > ul, 
.project-nav > ul {
  display: inline-flex;
  justify-content: space-around;
  width: inherit;
}

@media only screen and (max-width: 740px) {
  .project-nav > ul.project-nav-container {
    display: block;
  }
}

.primary-nav > ul {
  font-family: 'Alegreya Sans SC', sans-serif;
}

.project-nav > ul {
  min-width: 80vw;
  flex-wrap: wrap;
}

.primary-nav > ul > li > a,
.project-nav > ul > li > a {
  text-decoration: none;
  font-size: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.project-nav > ul > li > a {
  line-height: 1.5rem;
}

.primary-nav > ul > li > a:visited,
.project-nav > ul > li > a:visited {
  color: hsl(0, 0%, 20%);
}

.primary-nav > ul > li > .active,
.project-nav > ul > li > .active {
  font-weight: 700;
}

section {
  margin-left: 10%;
  margin-right: 10%;
  /* background-color: hsl(0, 0%, 96%); */
  padding-top: 1rem;
  padding-bottom: 2rem;
  height: fit-content;
  /* width: max-content; */
  /* max-width: 80%; */
  width: 80%;
}

ul {
  background-color: hsl(0, 0%, 96%);
  border-radius: 6px;
}

p {
  background-color: hsl(0, 0%, 96%);
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: .5rem;
  padding: 1rem;
  border-radius: 6px;
}

iframe {
  display: block;
  margin-bottom: 1rem;
  border-radius: 6px;
  box-shadow: 4px 4px 20px -10px hsl(0, 0%, 20%);
}

form {
  width: 50%;
  min-width: 350px;
  margin-left: 1rem;
}

input, textarea {
  border: none;
  display: block;
  width: 100%;
  background-color: hsl(0, 0%, 98%);
  margin-bottom: 1rem;
  padding: .5rem;
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  border-radius: 6px;
  box-shadow: 4px 4px 12px -5px hsl(0, 0%, 70%);
}

textarea {
  height: 10rem;
  resize: none;
}

input[type="submit"] {
  width: 50%;
  margin-left: 50%;
  transform: translateX(-50%);
  font-family: 'Alegreya Sans SC', sans-serif;
  font-weight: 500;
}

input[type="submit"]:hover {
  background-color: hsl(120, 50%, 90%);
}

input[type="submit"]:active {
  box-shadow: -4px -4px 12px -5px hsl(0, 0%, 70%);
}

footer {
  display: inline-flex;
  background-color: hsl(0, 0%, 96%);
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5rem;
  font-size: 1rem;
  padding-top: .25rem;
}

.intro-video {
  margin: 0 auto;
}

section.medium-widget {
  margin-left: 1rem;
  margin-right: 0;
}

.medium-widget-article__row {
  background-color: hsl(0, 0%, 96%);
  box-shadow: 4px 4px 12px -5px hsl(0, 0%, 70%);
  border-radius: 5px;
  margin-bottom: .5rem;
}

div.contrib-footer, div.day-tooltip {
  text-align: center;
}

text.wday, text.month {
  font-size: .75rem;
}

div.contrib-legend {
  display: none; 
}

#git-hub-calendar {
  margin-left: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: hsl(0, 0%, 98%);
  box-shadow: 0px 0px 10px -3px hsl(0, 0%, 70%);
  border-radius: 6px;
  /* Widget auto-loads a min-height of 175px, even when set to responsive.
  I chose to override with a highly specific tag rather than adding more
  on 'load' logic to React. */
  min-height: fit-content !important;
  --color-calendar-graph-day-bg: #dddbdb;
  --color-calendar-graph-day-L1-bg: #39dd34;
  --color-calendar-graph-day-L2-bg: #45a045;
  --color-calendar-graph-day-L3-bg: #047526;
  --color-calendar-graph-day-L4-bg: #0a4208;
}

.project-item {
  padding: .75rem;
  box-shadow: 0px 0px 10px -6px hsl(0, 0%, 70%);
  border-radius: 6px;
}

.project-nav > ul {
  width: 100%;
  display: inline-flex;
  justify-content: space-around;
  font-size: 1rem;
  padding-bottom: .5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid hsl(0, 0%, 20%);
}

.more-info {
  border: none;
  background-color: hsl(0, 0%, 98%);
  font-size: 1.5rem;
  margin-left: .5rem;
  transform: translateY(2px);
}

.external-link > a {
  margin-right: 1rem;
}

.slick-slider.slick-initialized {
  padding-bottom: 2rem;
  margin-bottom: .5rem;
}

.slick-prev:before, .slick-next:before {
  color: hsl(0, 0%, 20%);
}

.carousel-image {
  /* Override Library (React Slick) inline styling */
  width: 95% !important;
  margin-left: 2.5%;
  border-radius: 6px;
  box-shadow: 0px 0px 10px -6px hsl(0, 0%, 20%);
}

.slick-dots {
  bottom: 0;
  background-color: hsla(0, 0%, 0%, 0);
  margin-left: 50%;
  transform: translateX(-50%);
}

.slick-dots > li {
  width: 5%;
}

#decor-dragon {
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 1.5rem;
  width: 20%;
  min-width: 150px;
  height: auto;
  opacity: .5;
  filter: brightness(1.5);
}

.hidden {
  display: none;
}